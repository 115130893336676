@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.about-us-hero-container {
  height: 100%;
  width: 100%;
}

.section {
  position: relative;
  overflow: hidden;
  height: 485px;
  display: flex;
  align-items: center;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 fn.spacing(6);
    background: linear-gradient(
      344.37deg,
      rgb(0 0 0 / 72.9%) 0%,
      rgb(0 0 0 / 39.2%) 73.79%,
      rgb(0 0 0 / 0%) 101.87%
    );

    .info {
      .title {
        text-align: center;
      }

      .subtitle {
        text-align: center;
        margin-top: 12px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .section {
    .content {
      background: none;
      align-items: flex-end;
      padding: 0;

      .info {
        width: 485px;
        max-width: 485px;

        .title {
          text-align: left;
        }

        .subtitle {
          text-align: left;
          margin-top: fn.spacing(5);
        }
      }
    }
  }
}

@media only screen and (min-width: var.$breakpoint-xxl) {
  .section {
    .content {
      padding-right: fn.spacing(18.25);

      .info {
        width: 585px;
        max-width: 585px;
      }
    }
  }
}
